import {AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool} from 'amazon-cognito-identity-js'
import jwt_decode from "jwt-decode";

const userPoolId = process.env.REACT_APP_USERPOOL_ID
const clientId = process.env.REACT_APP_CLIENT_ID

const rememberMe = localStorage.getItem('AntiqueAppUserRemember')

const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
    Storage: rememberMe ? localStorage : window.sessionStorage
}

const userPool = new CognitoUserPool(poolData)

let currentUser = userPool.getCurrentUser()

export function getCurrentUser() {
    return currentUser
}

export async function isContributor() {
    return new Promise(function (resolve, reject) {
        try {
            const result = getSession().then((session) => {
                if(!session) return false
                const token = session.getAccessToken().getJwtToken()
                const decoded = jwt_decode(token)
                if(decoded['cognito:groups'] && decoded['cognito:groups'].length > 0) {
                    return decoded['cognito:groups'].find((group) => group === "MarketPlace" || group === "admin") !== undefined
                }
                return false

            })
            resolve(result)
        }
        catch (e) {
            reject(e)
        }
    })
}

function getCognitoUser(username) {
    const rememberMe = localStorage.getItem('AntiqueAppUserRemember')
    const userData = {
        Username: username,
        Pool: userPool,
        Storage: rememberMe ? localStorage : window.sessionStorage
    }
    return new CognitoUser(userData)
}

export async function getSession() {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser()
    }

    return new Promise(function (resolve, reject) {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err)
            } else {
                resolve(session)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function signUpUserWith(email, password, familyName, givenName, phone, newsletter, seller) {
    return new Promise(function (resolve, reject) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: familyName,
            }),
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: givenName,
            }),
            new CognitoUserAttribute({
                Name: 'custom:newsletter',
                Value: newsletter,
            }),
            new CognitoUserAttribute({
                Name: 'custom:phoneNumber',
                Value: phone,
            }),
            new CognitoUserAttribute({
                Name: 'custom:seller',
                Value: seller,
            }),
        ]

        userPool.signUp(email, password, attributeList, [], function (err, res) {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function verifyCodeFor(email, code) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err)
            } else {
                resolve(result)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function signInWith(email, password) {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: email,
            Password: password,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)

        currentUser = getCognitoUser(email)

        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res) {
                resolve(res)
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    }).catch((err) => {
        throw err
    })
}

export function signOut(success, failure) {
    return getSession().then((r)=>{
        if(r) {
            return getCurrentUser().globalSignOut({
                onSuccess: (result) => {
                    success(result)
                },
                onFailure: (err) => {
                    failure(err)
                },
            });
        }
    }).catch((e)=>{
        failure(e)
    })
}

export async function getAttributes() {
    const cognitoUser = currentUser

    return new Promise(function (resolve, reject) {
        if(cognitoUser) {
            cognitoUser.getSession(function (err) {
                if (err) {
                    reject(err)
                }
                cognitoUser.getUserAttributes(function (err, attributes) {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(attributes)
                    }
                });
            })
        } else {
            throw new Error('Invalid session')
        }
    }).catch((err) => {
        throw err
    })
}

export async function updateAttributeList(data) {
    const attributeList = [
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: data.familyName,
        }),
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: data.givenName,
        }),
        new CognitoUserAttribute({
            Name: 'custom:newsletter',
            Value: data.newsletter?"1":"0",
        }),
        new CognitoUserAttribute({
            Name: 'custom:phoneNumber',
            Value: data.phone,
        }),
    ]
    return new Promise(function (resolve, reject) {
        getCurrentUser().updateAttributes(attributeList, (err, res) => {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    }).catch((err) => {
        throw err
    })
}

export async function forgotPasswordFor(email) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        if (!cognitoUser) {
            reject(`could not find ${email}`)
            return
        }

        cognitoUser.forgotPassword({
            onSuccess: function (res) {
                resolve(res)
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    }).catch((err) => {
        throw err
    })
}

export async function newPasswordFor(email, code, newPassword) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email)

        if (!cognitoUser) {
            reject(`could not find ${email}`)
            return
        }

        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: function () {
                resolve('password updated')
            },
            onFailure: function (err) {
                reject(err)
            },
        })
    })
}

export async function changePassword(oldPassword, newPassword) {
    return new Promise(function (resolve, reject) {
        currentUser.changePassword(oldPassword, newPassword, function (err, res) {
            if (err) {
                reject(err)
            } else {
                resolve(res)
            }
        })
    })
}

export async function refreshTokenFor(email) {
    return new Promise(function (resolve, reject) {
        const session = getSession();
        const cognitoUser = getCognitoUser(email)

        if (!session || !cognitoUser) {
            reject(`could not find session for ${email}`)
            return
        }
        cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
                console.log(err);
            } else {
                resolve(session)
            }
        });
    })
}