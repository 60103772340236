import * as React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import EmailIcon from "@mui/icons-material/Email";
import {useTranslation} from "react-i18next";
import CopyrightIcon from '@mui/icons-material/Copyright';
import MenuItem from "@mui/material/MenuItem";
import AntiqueDarkButton from "../AntiqueDarkButton/AntiqueDarkButton";

function Copyright(props) {

    const {name} = props
    return (
        <React.Fragment>
            <Typography variant="overline" display="block" gutterBottom color={"white"}>
                {name}
                <CopyrightIcon/>
                {new Date().getFullYear()}
            </Typography>
        </React.Fragment>
    );
}

export default function AntiqueAppFooter() {

    const {t} = useTranslation();

    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: "#191919", minHeight: "10vh", flexDirection: "column" }}
        >
            <Container sx={{ my: 4, display: 'flex' }}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-end"
                            spacing={1}
                        >
                            <Grid item>
                                <Copyright name={t('copyright')}/>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                    <Link href="mailto:contact@anticariatelisei.ro">contact@anticariatelisei.ro <EmailIcon/></Link>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <Link href="https://www.eliseiantiques.com" target={"_blank"}>
                                    https://www.eliseiantiques.com
                                </Link>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <Link href="https://www.anticariatelisei.ro" target={"_blank"}>
                                    https://www.anticariatelisei.ro
                                </Link>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item>
                        <MenuItem sx={{color:'white'}}>
                            <AntiqueDarkButton color={"white"} />
                        </MenuItem>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}