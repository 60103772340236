import React from "react";
//import './App.css';
import {BrowserRouter, Route} from "react-router-dom";
import {AppLanguage, AppRoute} from './const';

import {LocalizedRouter} from './components/index';

import Index from './pages/Index/Index'
import AboutUs from './pages/AboutUs/AboutUs'
import AntiqueShop from './pages/AntiqueShop/AntiqueShop'
import GetAQuote from './pages/GetAQuote/GetAQuote'
import NoMatch from './pages/NoMatch/NoMatch'
import MyPlace from "./pages/MyPlace/MyPlace";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import {AntiqueTheme} from "./components/AntiqueTheme/AntiqueTheme";
import {defaultLanguage} from './i18n';
import AntiqueApolloProvider from "./providers/AntiqueApolloProvider";
import AntiqueProtectedRoute from "./components/AntiqueProtectedRoute/AntiqueProtectedRoute";
import SignOut from "./pages/SignOut/SignOut";
import MyProfile from "./pages/MyProfile/MyProfile";
import AntiqueAnonymousRoute from "./components/AntiqueAnonymousRoute/AntiqueAnonymousRoute";
import AntiqueMarketPlaceProtectedRoute
    from "./components/AntiqueMarketPlaceProtectedRoute/AntiqueMarketPlaceProtectedRoute";
import Discover from "./pages/Discover/Discover";

function App() {

    return (
        <AntiqueApolloProvider>
            <AntiqueTheme>
                <LocalizedRouter
                    RouterComponent={BrowserRouter}
                    languages={AppLanguage} defaultLanguage={defaultLanguage}>
                    <Route path={AppRoute.Forbidden} element={<NoMatch mess={"Forbidden"}/>}/>
                    <Route path={AppRoute.Index} element={<Index/>}/>
                    <Route path={AppRoute.AboutUs} element={<AboutUs/>}/>
                    <Route path={AppRoute.Discover} element={<Discover/>}/>
                    <Route path={AppRoute.AntiqueShop} element={<AntiqueShop/>}/>
                    <Route path={AppRoute.GetAQuote} element={<GetAQuote/>}/>
                    <Route path={AppRoute.MyPlace} element={
                        <AntiqueProtectedRoute>
                            <AntiqueMarketPlaceProtectedRoute>
                                <MyPlace/>
                            </AntiqueMarketPlaceProtectedRoute>
                        </AntiqueProtectedRoute>
                    }/>
                    <Route path={AppRoute.SignOut} element={
                        <AntiqueProtectedRoute>
                            <SignOut/>
                        </AntiqueProtectedRoute>
                    }/>
                    <Route path={AppRoute.MyProfile} element={
                        <AntiqueProtectedRoute>
                            <MyProfile/>
                        </AntiqueProtectedRoute>
                    }/>
                    <Route path={AppRoute.SignIn} element={
                        <AntiqueAnonymousRoute>
                            <SignIn/>
                        </AntiqueAnonymousRoute>
                    }/>
                    <Route path={AppRoute.SignUp} element={
                        <AntiqueAnonymousRoute>
                            <SignUp/>
                        </AntiqueAnonymousRoute>
                    }/>
                    <Route path="*" element={<NoMatch mess={"Error, Page Not Found"}/>}/>
                </LocalizedRouter>
            </AntiqueTheme>
        </AntiqueApolloProvider>
    );
}

export default App;
