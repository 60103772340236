import React from "react";
import {useTranslation} from "react-i18next";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import AntiqueIndexCard from "../../components/AntiqueIndexCard/AntiqueIndexCard";
import {Box, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {styled} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import {AppRoute, AppRouteTitle} from "../../const";
import {S3_IMAGE_URL} from "../../const";
import { Helmet } from 'react-helmet';

const Background = styled(Box)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
});


const Index = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const returnLink = (url) => {
        return `/${i18n.resolvedLanguage}` + t(url);
    }

    const navigateToAboutUs = () => {
        navigate(returnLink(t(AppRoute.AboutUs)))
    }

    const navigateToDiscover = () => {
        navigate(returnLink(t(AppRoute.Discover)))
    }

    const navigateToShop = () => {
        navigate(returnLink(t(AppRoute.AntiqueShop)))
    }

    const navigateToQuote = () => {
        navigate(returnLink(t(AppRoute.GetAQuote)))
    }

    const getImgSrc = (name) => {
        return S3_IMAGE_URL+ name
    }

    const backgroundImage = S3_IMAGE_URL + 'index2.jpg';

    return (
        <div>
            <Helmet>
                <title>{t(AppRouteTitle.Index)}</title>
                <meta name="description" content={t('Index.description')} />
                <meta name="keywords" content={t('Index.keywords')} />
            </Helmet>
            <AntiqueAppBar/>
            <Container style={{height: '100%'}}>
                <Container
                    sx={{
                        mt: 3,
                        mb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <img
                        style={{display: 'none'}}
                        src={backgroundImage}
                        alt="increase priority"
                    />
                    <Typography color="white" align="center" variant="h2" marked="center">
                        {t('Index.welcome')}
                    </Typography>
                    <Typography
                        color="white"
                        align="center"
                        variant="h5"
                        sx={{mb: 4, mt: {sx: 4, sm: 10}}}
                    >
                        {t('Index.description')}
                    </Typography>

                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: 'common.black',
                            opacity: 0.0,
                            zIndex: -1,
                        }}
                    />
                    <Background sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundColor: '#7fc7d9', // Average color of the background image.
                        backgroundPosition: 'center',
                    }}/>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <AntiqueIndexCard
                                    title={t('title.aboutus')}
                                    description={t('aboutUs.cardDescription')}
                                    alt={t('title.aboutus')}
                                    imgSrc={getImgSrc("aboutus.jpg")}
                                    onClick={navigateToAboutUs}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AntiqueIndexCard
                                    title={t('title.discover')}
                                    description={t('Discover.cardDescription')}
                                    alt={t('title.discover')}
                                    imgSrc={getImgSrc("discover.jpg")}
                                    onClick={navigateToDiscover}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AntiqueIndexCard
                                    title={t('title.getaquote')}
                                    description={t('Quote.cardDescription')}
                                    alt={t('title.getaquote')}
                                    imgSrc={getImgSrc("index.jpg")}
                                    onClick={navigateToQuote}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AntiqueIndexCard
                                    title={t('title.antiqueshop')}
                                    description={t('shop.cardDescription')}
                                    alt={t('title.antiqueshop')}
                                    imgSrc={getImgSrc("firstPage.jpg")}
                                    onClick={navigateToShop}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}

export default Index;