import React, {useEffect, useState} from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Card, CardActions, CircularProgress, Container, Divider, Grid} from "@mui/material";
import ShopItem from "../../components/ShopItem/ShopItem";
import ShopItemForm from "../../components/ShopItemForm/ShopItemForm";
import Typography from '@mui/material/Typography';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CardContent from "@mui/material/CardContent";
import {useQuery, gql} from '@apollo/client';
import AntiqueCard from "../../components/AntiqueCard/AntiqueCard";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AntiqueAddShopCard from "../../components/AntiqueAddShopCard/AntiqueAddShopCard";
import {Helmet} from "react-helmet";
import {AppRouteTitle} from "../../const";

const AntiqueShop = () => {

    const {t} = useTranslation();

    const variable =  {
        limit : 20
    }

    const GET_PRODUCTS = gql`
  query QueryProducts($limit: Int, $nextToken: String) {
  getProducts(limit: $limit, nextToken: $nextToken) {
    products {
      userId
      currency
      descriptions {
        language
        desc
        longDesc
        title
      }
      id
      name
      price
      status
      images {
        normal
        thumb
      }
    }
    nextToken
  }
}

`;

    const [nextToken, setNextToken] = useState('')

    if(nextToken.length > 0) {
        variable.nextToken = nextToken
    }

    const {loading, error, data} = useQuery(GET_PRODUCTS, {
        variables: variable
    });

    const [state, setState] = useState({open: false, product: {}, loading: loading});

    const [products, setProducts] = useState([])

    const onBuy = (id) => {
        if (loading || error || products.length === 0) return;
        const presentedProduct = products.find(element => element.id === id);
        setState({open: true, product: presentedProduct, loading: false});
    }

    const handleClose = () => {
        setState({open: false, product: {}, loading: false});
    }



    useEffect(()=>{
        if(!loading && data && !error) {
            if(nextToken.length > 0) {
                setProducts((p)=>[...p, ...data.getProducts.products])
            }
            else {
                setProducts(()=>[...data.getProducts.products])
            }
        }
    },[loading, data, error, nextToken])

    return (
        <div>
            <Helmet>
                <title>{t(AppRouteTitle.AntiqueShop)}</title>
                <meta name="description" content={t('shop.description')} />
                <meta name="keywords" content={t('shop.keywords')} />
            </Helmet>
            <AntiqueAppBar/>

            <Container>
                <Typography variant="h6" component="h2">{t('title.antiqueshop')}</Typography>
                <Divider>{t('products')}</Divider>
                {
                    error ?
                            <Alert severity="error">
                                <ul>
                                    {
                                        error.graphQLErrors.map(error => {
                                            return (
                                                <li>{error.message}</li>
                                            );
                                        })
                                    }
                                </ul>
                            </Alert>
                        :
                        ''
                }
                <Box sx={{p: 2}}>
                    <Grid container spacing={2}>
                        {
                            loading ?
                                <Grid item md={12} xs={12} sm={12} lg={12}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <CircularProgress/>
                                    </div>
                                </Grid>
                                :
                                !error && products.length > 0 ?

                                    products.map(item => {
                                        return (
                                            <Grid item md={4} xs={12} sm={6} lg={3} key={item.id}>
                                                <ShopItem {...item}>
                                                    <CardActions>
                                                        <Button size="small"
                                                                onClick={() => onBuy(item.id)}>{t('shop.Buy')}</Button>
                                                        <Button size="small"
                                                                onClick={() => onBuy(item.id)}>{t('shop.More_Details')}</Button>
                                                    </CardActions>
                                                </ShopItem>
                                            </Grid>
                                        );
                                    })
                                    :
                                    <Grid item xs={10} md={10} sm={10} lg={9}>

                                        <Card>
                                            <CardContent>
                                                <Typography sx={{fontSize: 24}} color="text.secondary" gutterBottom>
                                                    {t('shop.No_Products_Found')} <SentimentVeryDissatisfiedIcon
                                                    fontSize={"large"}/>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t('shop.Come_Back_Later')}
                                                </Typography>
                                            </CardContent>
                                        </Card>

                                    </Grid>

                        }
                        {
                            !loading && !error && data?.getProducts?.nextToken?.length > 0 ?
                                <Grid item md={4} xs={12} sm={6} lg={3} key={10000}>
                                    <AntiqueCard onClick={() => setNextToken(data.getProducts.nextToken)} title={t('shop.moreTitle')} icon={<MoreHorizIcon/>}/>
                                </Grid>
                                :
                                ''
                        }

                        <Grid item md={4} xs={12} sm={6} lg={3} key={100}>
                            <AntiqueAddShopCard/>
                        </Grid>
                    </Grid>
                    <ShopItemForm open={state.open} {...state.product} handleClose={handleClose}/>



                </Box>
            </Container>
            <AntiqueAppFooter/>
        </div>
    )
}

export default AntiqueShop;