import React from "react";
import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar"
import {useTranslation} from "react-i18next";
import AntiqueAppFooter from "../../components/AntiqueAppFooter/AntiqueAppFooter";
import {ContactView} from "../../views/Contact/ContactView";
import {Box, Card, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import EmailIcon from '@mui/icons-material/Email';
import Link from "@mui/material/Link";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import AntiquePortofolio from "../../components/AntiquePortofolio/AntiquePortofolio";
import {AppRouteTitle, S3_IMAGE_URL} from "../../const";
import CallIcon from '@mui/icons-material/Call';
import {Helmet} from "react-helmet";

const AboutUs = () => {

    const {t} = useTranslation();

    const imgSrc = S3_IMAGE_URL + "aboutus.jpg"

    const generateNumber = () => {
        const n = ["1","1","1"," ","7","9","4",") ","9","4","7","0"," (","4","+"]
        let buffer = ""
         n.map((nr)=>{
             buffer = nr + buffer
             return ''
         })
        return buffer;
    }

    return (
        <div>
            <Helmet>
                <title>{t(AppRouteTitle.AboutUs)}</title>
                <meta name="description" content={t('aboutUs.description')} />
                <meta name="keywords" content={t('aboutUs.keywords')} />
            </Helmet>
            <AntiqueAppBar/>
            <div
                style={{width: '100%'}}>
                <Container>
                    <Card sx={{maxWidth: '100%', m: 1}}>

                        <CardMedia
                            component="img"
                            height="140"
                            image={imgSrc}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography variant="h5" component="h2">{t('title.aboutus')}</Typography>


                            <Box sx={{p: 1, m: 1}}>
                                {t('aboutUs.description')}
                            </Box>
                            <Box sx={{p: 1, m: 1}}>
                                {t('aboutUs.description2')}
                            </Box>
                            <Toolbar  />
                            <Typography variant="h6" component="h2">{t('aboutUs.portofolio')}</Typography>
                            <Box sx={{p: 1, m: 1}}>
                                <AntiquePortofolio/>
                            </Box>
                            <Toolbar  />
                            <Typography variant="h6" component="h2">{t('aboutUs.contactTitle')}</Typography>
                            <Box sx={{p: 1, m: 1}}>
                                <ContactView/>
                            </Box>
                            <Toolbar  />
                            <Typography variant="h6" component="h2">{t('aboutUs.addressTitle')}</Typography>
                            <Box sx={{p: 1, m: 1}}>
                                {t('aboutUs.addressDescription')}
                            </Box>
                            <Box sx={{p: 1, m: 1}}>
                                <Link href="mailto:contact@anticariatelisei.ro"><EmailIcon/> contact@anticariatelisei.ro</Link>
                                <Typography variant="h6" component="h2"><CallIcon/> {generateNumber()}</Typography>
                            </Box>
                            <Toolbar  />
                            <Typography variant="h6" component="h2">{t('aboutUs.workingHourTitle')}</Typography>
                            <Box sx={{p: 1, m: 1}}>
                                {t('aboutUs.workingHourDescription')}
                            </Box>


                        </CardContent>
                    </Card>


                </Container>

            </div>

            <AntiqueAppFooter/>
        </div>
    )
}

export default AboutUs;