import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FormInput} from "../FormInput/FormInput";
import {useTranslation} from "react-i18next";
import {getAttributes, getSession, refreshTokenFor, updateAttributeList} from "../../services/cognito"
import {useContext, useEffect, useState} from "react";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {FormCheckbox} from "../FormCheckBox/FormCheckBox";
import {UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {Snackbar} from "@mui/material";

const AntiqueUserForm = () => {

    const yupObject = {
        familyName: yup.string().required('forms.signUpForm.errors.familyRequired').min(5,'forms.signUpForm.errors.familyMin').max(50,'forms.signUpForm.errors.familyMax'),
        givenName: yup.string().required('forms.signUpForm.errors.givenRequired').min(5,'forms.signUpForm.errors.givenMin').max(50,'forms.signUpForm.errors.givenMax'),
        newsletter: yup.boolean().required().oneOf([true, false],'Message'),
        phone: yup.string().min(10, 'forms.signUpForm.errors.phoneMin').max(20, 'forms.signUpForm.errors.phoneMax'),
    }

    const schema = yup.object(yupObject).required();

    const userAuth = useContext(UserContext)
    const dispatch = useContext(UserDispatchContext)

    const [updateErrors, setUpdateErrors] = useState([])
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = React.useState(false);
    const [first, setFirst] = useState(true)

    useEffect(()=>{
        if(first) {

            getAttributes().then((r) => {
                const authObject = {}
                r.map((item) => {
                    switch (item.getName()) {
                        case "family_name":
                            authObject.FamilyName = item.getValue()
                            break
                        case "given_name":
                            authObject.GivenName = item.getValue()
                            break
                        case "custom:phoneNumber":
                            authObject.PhoneNumber = item.getValue()
                            break
                        case "custom:newsletter":
                            authObject.Newsletter = parseInt(item.getValue(), 10)
                            break
                        default:
                    }
                    return true

                })
                setFirst(false)
                setLoading(false)
                dispatch({
                    type: 'updateUser',
                    response: authObject
                })
            }).catch((errors)=>{
                setFirst(false)
                setLoading(false)
                setUpdateErrors([errors.toString()])
            })
        }
    })

    const {handleSubmit, control, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email:userAuth.Email,
            familyName:userAuth.FamilyName,
            givenName:userAuth.GivenName,
            phone:userAuth.PhoneNumber,
            newsletter:!!userAuth.Newsletter
        },
    });

    const onSubmit = (data) => {
        setLoading(true)
        getSession().then((r)=>{

            if(r.isValid()) {
                try {
                    updateAttributeList(data).then(()=>{
                        const authObject = {
                            FamilyName : data.familyName,
                            GivenName : data.givenName,
                            PhoneNumber : data.phone,
                            Email : data.email,
                            Newsletter : data.newsletter ? 1 : 0,
                        }
                        setLoading(false)
                        dispatch({
                            type: 'updateUser',
                            response:authObject
                        })
                        setOpen(true)
                    })
                }
                catch (e) {
                    throw e
                }
            } else {
                try {
                    refreshTokenFor(data.email).then(()=>{
                        try {
                            updateAttributeList(data).then(()=>{
                                const authObject = {
                                    FamilyName : data.familyName,
                                    GivenName : data.givenName,
                                    PhoneNumber : data.phone,
                                    Email : data.email,
                                    Newsletter : data.newsletter ? 1 : 0,
                                }
                                setLoading(false)
                                dispatch({
                                    type: 'updateUser',
                                    response:authObject
                                })
                                setOpen(true)
                            })
                        }
                        catch (e) {
                            throw e
                        }
                    })
                }
                catch (e) {
                    throw e
                }
            }
        }).catch((errors)=>{
            setUpdateErrors([errors.toString()])
            setLoading(false)
        })
    }

    const [t] = useTranslation();

    const getLabel = (label) => {
        return t('forms.signUpForm.labels.'+label)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:'bottom', horizontal:'left' }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {getLabel('updatedSuccessfully')}
                </Alert>
            </Snackbar>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            {updateErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {updateErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <Container>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {t(getLabel('userDetails'))}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormInput name={"email"} control={control} errors={errors}
                                       label={t(getLabel("emailAddress"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                id: "email",
                                helperText : errors?.email?.message ? t(errors.email.message) : ''
                            }} disabled={true}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"familyName"} control={control} errors={errors}
                                       label={t(getLabel("familyName"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                required: true,
                                id: "familyName",
                                disabled : loading,
                                helperText : errors?.familyName?.message ? t(errors.familyName.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"givenName"} control={control} errors={errors}
                                       label={t(getLabel("givenName"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                required: true,
                                id: "givenName",
                                disabled : loading,
                                helperText : errors?.givenName?.message ? t(errors.givenName.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormInput name={"phone"} control={control} errors={errors}
                                       label={t(getLabel("phone"))} InputProps={{
                                margin: "normal",
                                fullWidth: true,
                                id: "phone",
                                disabled : loading,
                                helperText : errors?.phone?.message ? t(errors.phone.message) : ''
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox name={"newsletter"} InputProps={{disabled:loading}} control={control} label={t(getLabel("newsletter"))} errors={errors}/>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t(getLabel("updateDetails"))}
                    </Button>

                </Box>
            </Container>
        </form>
    );
}
export default AntiqueUserForm