import React from 'react';
import {Route, Routes, Navigate, useParams} from 'react-router-dom';
import NoMatch from "../../pages/NoMatch/NoMatch";
import {LocalizedRoutes} from "../LocalizedRoutes/LocalizedRoutes";
import { useTranslation } from 'react-i18next';

const Matcher = ({children,defaultLanguage}) => {

    const { i18n } = useTranslation();

    const params = useParams();
    const {lang = defaultLanguage} = params;

    /**
     * If language is not in route path, redirect to language root
     */
    if (i18n.getDataByLanguage(lang) === undefined) {
        i18n.changeLanguage(defaultLanguage);
        return <Navigate to={`/${defaultLanguage}/`}/>;
    }


    /**
     * Return Intl provider with default language set
     */
    return (
        <div>


                <LocalizedRoutes children={children}/>

        </div>

    );
}

export const LocalizedRouter = ({
                                 children,
                                 RouterComponent,
                                 defaultLanguage,
                             }) => {

    return (
        <RouterComponent>
            <Routes>
                <Route path="/:lang/*" element={<Matcher defaultLanguage={defaultLanguage}>{children}</Matcher>} />
                <Route path="//*" element={<Matcher defaultLanguage={defaultLanguage}>{children}</Matcher>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </RouterComponent>);
    };