import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FormInput} from "../FormInput/FormInput";
import {useTranslation} from "react-i18next";
import {forgotPasswordFor} from "../../services/cognito"
import {useState} from "react";
import AntiqueBackdrop from "../AntiqueBackdrop/AntiqueBackdrop";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {useNavigate} from "react-router-dom";
import {AppRoute} from "../../const";
import AntiqueDialog from "../AntiqueDialog/AntiqueDialog";
import {DialogActions} from "@mui/material";

const ResetPasswordForm = () => {

    const [t] = useTranslation();

    const schema = yup.object({
        email: yup.string().email('forms.signInForm.errors.userName').required('forms.signInForm.errors.userNameRequired'),
    }).required();

    const navigate = useNavigate()

    const [resetErrors, setResetErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)

    const {handleSubmit, control, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {email:''}
    });

    const onSubmit = (data) => {
        setLoading(true)
        forgotPasswordFor(data.email).then((r)=>{
            setLoading(false)
            setResult(t('forms.signInForm.labels.resetPasswordSuccessfully')+r.CodeDeliveryDetails.Destination)

        }).catch((errors)=>{
            setResetErrors([errors.toString()])
            setLoading(false)
        })
    }



    const redirect = () => {
        navigate("./../.." + t(AppRoute.SignIn)+t('routes.newpassword'))
    }

    const getLabel = (label) => {
        return t('forms.signInForm.labels.'+label)
    }

    const handleClose = () => {
        setResult(null);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <AntiqueDialog
                title={t(getLabel('resetPassword'))}
                open={result?true:false}
                handleClose={handleClose}
                description={result}
            >
                <DialogActions>
                    <Button onClick={()=>redirect()}>{t('shop.close')}</Button>
                </DialogActions>
            </AntiqueDialog>
            <AntiqueBackdrop loading={loading} color={"inherit"} text={t('sendingForm')}/>
            {resetErrors.length ?
                (
                    <Alert severity="error">
                        <AlertTitle>{t('error')}</AlertTitle>
                        <ul>
                            {resetErrors.map((error, idx) => {
                                return <li key={idx}>{error}</li>
                            })}
                        </ul>
                    </Alert>
                ) :
                ''
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t(getLabel('forgotPassword'))}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormInput name={"email"} control={control} errors={errors}
                                       label={t(getLabel("emailAddress"))} InputProps={{
                                margin: "normal",
                                required: true,
                                disabled : loading,
                                fullWidth: true,
                                id: "email",
                                autoComplete: "email",
                                autoFocus: true,
                                helperText: errors?.email?.message ? t(errors.email.message) : ''
                            }}/>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t(getLabel("reset"))}
                    </Button>
                </Box>
            </Container>
        </form>
    );
}
export default ResetPasswordForm