import React from 'react'

import {AntiqueAppBar} from "../../components/AntiqueAppBar/AntiqueAppBar";

import {useContext, useEffect} from "react";
import {UserDispatchContext} from "../../contexts/UserContext";
import {signOut} from "../../services/cognito";

const SignOut = () => {

    const dispatch = useContext(UserDispatchContext)


    useEffect(()=>{
        signOut(()=>{
            dispatch({
                type: 'signOut'
            })
        }, ()=>{
            dispatch({
                type: 'signOut'
            })
        })


    })

    return (
        <div>
            <AntiqueAppBar/>
            <div style={{
                width: '100%',
            }}>
                Logged out. We'll wait you again
            </div>
        </div>
    )
}

export default SignOut