import React, {useReducer} from 'react'
import {UserContext, UserDispatchContext} from "../contexts/UserContext";
import UserReducer from "../reducers/UserReducer";

const AntiqueUserProvider = ({children}) => {

    const rememberMe = localStorage.getItem('AntiqueAppUserRemember')
    let initialState

    if(rememberMe) {
        initialState = localStorage.getItem('AntiqueAppUser')?JSON.parse(localStorage.getItem('AntiqueAppUser')):null;
    } else{
        initialState = sessionStorage.getItem('AntiqueAppUser')?JSON.parse(sessionStorage.getItem('AntiqueAppUser')):null;
    }

    const [state, dispatch] = useReducer(UserReducer, initialState?initialState: null)

    return (
        <UserContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    )

}

export default AntiqueUserProvider